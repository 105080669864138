.ForgottenPassword_Wrapper {
  background-color: #284285;
  padding: 2rem;
  border-radius: 5px;
}

.ForgottenPassword_Header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  color: #fff;
  margin-bottom: 1rem;
}

.ForgottenPassword_Header_Logo {
  font-size: 35px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #fff;
}

.ForgottenPassword_Header_FormTitle {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.ForgottenPassword_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.ForgottenPassword_LoginBtn {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 2px;
  color: #284285;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  height: 40px;
}

.ForgottenPassword_TextWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.3rem;
}
.ForgottenPassword_Form_Label {
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  margin-left: 15px;
}

.ForgottenPassword_Form_Input {
  background-color: #284285 !important;
  width: 285px;
  border: 0.5px #fff solid;
  height: 40px;
  padding-left: 15px;
}

.ForgottenPassword_Footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}

.ForgottenPassword_Footer > span {
  color: #fff;
  font-size: 12px;
}
.ForgottenPassword_Footer > span > span {
  cursor: pointer;
  color: #ffc60c;
}
