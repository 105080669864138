.options {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 1rem;
  color: #011b60;
}

.profile-icon {
  width: 15px;
  height: 15px;
}
