.top-nav-sliding{
    padding: 25px;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
}

.sliding-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.sliding-closebtn{
    color: #000000;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.sliding-icon{
    cursor: pointer;
}

.sliding-links{
    flex: 3;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0;
}

.sliding-links a {
    text-decoration: none;
    font-size: 14px;
}

.sliding-text{
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #011B60;
}

.sliding-text span{
    font-weight: bold;
}