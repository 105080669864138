.landing_container {
  width: 100%;
  position: relative;
  height: calc(100vh - 80px);
}

a {
  color: #02105f;
}

.landing {
  background-color: #f5f5f5;
  width: 100%;
  height: calc((100vh - 80px) - 90px);
  padding: 20px 50px;
  color: #02105f;
}

.landing_top {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.landing_top_text {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.landing_top_image {
  display: flex;
  align-items: center;
  justify-self: center;
}

.landing_text_header {
  line-height: 48px;
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 0.5px;
  width: 100%;
}

.landing_text_paragraph {
  font-size: 20px;
  font-weight: 400;
  margin: 5% 0 3%;
  width: 90%;
}

.landing_text_link {
  font-weight: 550;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landing_top_image {
  width: 600px;
  height: 100%;
}

.top-image-view {
  height: 80%;
  width: 100%;
}

.landing_bottom_map,
.landing_bottom_text,
.landing_bottom_icons {
  flex: 1;
}

.landing_bottom {
  color: #ffffff;
  background-color: #0a5fff;
  width: 85%;
  height: 170px;
  padding: 20px 10px;
  border-top-left-radius: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.landing_bottom_map {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.landing_map_text {
  margin-left: 10px;
  font-size: 25px;
  font-weight: 500;
}

.landing_map_img {
  margin-top: 20px;
  margin-left: 30px;
  height: 100px;
}

.landing_bottom_text {
  font-size: 15px;
  line-height: 25px;
}

.landing_bottom_icons {
  display: flex;
  flex-direction: row;
  margin: 0 10px;
}

.landing_bottom_icons div {
  flex: 1;
}

.sydani-img{
  padding: 10px;
  background-color: #ffffff;
}

.landing_bottom_icons div > img {
  width: 100%;
}

.bottom-icons {
  height: 60px;
}

.sydani-link{
  color: #ffffff;
}

@media screen and (max-width: 390px) {
  .landing_container {
    height: calc(100vh - 60px);
  }

  .landing {
    height: calc((100vh - 60px) - 200px);
    padding: 5% 6% 4%;
  }

  .landing_top {
    flex-direction: column-reverse;
  }

  .landing_top_text {
    width: 100%;
    padding: 10px 0;
    height: 100%;
  }

  .landing_text_header {
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
  }

  .landing_text_paragraph {
    font-size: 13px;
    font-weight: 400;
    margin: 5% 0 3%;
    width: 90%;
  }

  .landing_top_text,
  .landing_top_image {
    flex: 1;
  }

  .landing_top_image {
    width: 75%;
    height: 50%;
  }

  .landing_bottom_text br,
  .landing_text_paragraph br {
    display: none;
  }

  .landing_text_link {
    text-decoration: none;
    border-radius: 3px;
    padding: 6px;
    background-color: #0a5fff;
    color: #ffffff;
    font-weight: normal;
    font-size: 14px;
    justify-content: center;
  }

  .landing_text_link svg {
    display: none;
  }

  .landing_bottom {
    width: 100%;
    height: 200px;
    padding: 5px 20px;
    border-top-left-radius: 0;
    position: static;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
  }

  .landing_map_img {
    display: none;
  }

  .bottom-icons {
    height: 40px;
  }

  .landing_bottom_text {
    font-size: 12px;
  }

  .landing_map_text {
    margin-left: 0;
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .landing_bottom_icons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}
