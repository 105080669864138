.resources-root-container {
  height: 100%;
  /* display: flex; */
  width: 100vw;
  /* flex-direction: column; */
  gap: 20;
  scroll-behavior: smooth;
}

.resources-root-container a{
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.bold-link{
  font-weight: 500;
}

.resources-header {
  height: 400px;
  display: flex;
  flex-direction: column;
}

.header-nav {
  padding: 0 80px;
  height: 100px;
  background-color: #0a5fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 100px;
}

.header-body {
  padding: 0 80px;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #e5edff;
  color: #02105f;
}

.header-body-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.header-body-left h1 {
  width: 100%;
  font-size: 40px;
}

.header-body-left p {
  width: 100%;
  font-size: 14px;
}

.header-body-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-body-right img{
  height: 80%;
  width: 80%;
}

.resources-body,
.resources-footer {
  padding: 0 80px;
}

.resources-body {
  padding-top: 40px;
}

.resources-body {
}

.body-content-header {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  color: #02105f;
}

.body-content {
  padding: 30px 0;
  color: #02105f;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.customer-service,
.growth-service,
.health-service {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.customer-top,
.growth-top,
.health-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f4f5f9;
}

.top-text {
  font-size: 20px;
  font-weight: 500;
}

.top-subtitle {
  font-size: 14px;
  font-weight: 500;
}

.customer-bottom,
.growth-bottom,
.health-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.customer-bottom div{
  flex-basis: 33%
}

.bottom-content {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bottom-image{
  height: 100%;
  width: 100%;
}

.bottom-p-1 {
  font-size: 12px;
}

.bottom-p-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .5px;
}

.resources-footer {
  padding: 50px 80px;
  background-color: #f4f5f9;
  color: #02105f;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footer-left,
.footer-right {
  flex: 1;
}

.footer-left,
.footer-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-left p {
  font-size: 13px;
}

.group-input {
  display: flex;
  flex-direction: row;
}

.group-input input {
  background-color: #ffffff;
  padding: 10px 25px;
}

.group-input button {
  width: 200px;
  border-radius: 3px;
  background-color: #0a5fff;
  color: #ffffff;
  font-weight: 500;
}

.footer-right p {
  font-size: 12px;
}

.footer-right p a {
  font-weight: 500;
  color: #02105f;
  border-bottom: 1px solid #02105f;
}

.contact-form,
.contact-image{
  flex: 1;
}

.contact-image img{
  width: 100%;
  height: 100%;
}