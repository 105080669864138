.summary-root-container {
  background-color: #ffffff;
  display: flex;
  color: #02105f;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  position: relative;
}

.table-root-container {
  background-color: #ffffff;
  color: #011b60;
  display: flex;
  color: #02105f;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  position: relative;
  gap: 40px;
  padding: 15px 40px;
}

.table-area {
  width: 100%;
  height: 100%;
  margin: 5px 30px 20px 5px;
}

.chart-root-container {
  position: relative;
  background-color: #ffffff;
  color: #011b60;
  display: flex;
  color: #02105f;
  flex-direction: column;
  align-items: center;
  justify-content: sapce;
  height: calc(100vh - 200px);
  position: relative;
  gap: 5px;
  padding: 15px 40px;
}

.chart-top-1,
.chart-top-2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.chart-root-top {
  width: 100%;
  padding: 8px 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  /* margin-bottom: 30px; */
}

.table-root-top {
  /* border: 3px solid red; */
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.table-top-1,
.table-top-2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

#bold {
  font-weight: bold;
}

.table-root-bottom {
  height: 300px;
}

.table-root-top p,
.chart-root-top p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.chart-root-top p{
  font-size: 17px;
}

.table-root-top button,
.chart-root-top button {
  cursor: pointer;
  border: none;
  border-radius: 3px;
  border: 1px solid #02105f6e;
  background-color: #c8d5f4;
  color: #011b60;
  font-weight: bold;
  padding: 8px;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chart-root-container .pie-font {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
}

.table-root-container .recharts-responsive-container {
  height: 300px;
}

.summary-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  padding: 6% 40px;
}

.summary-bottom-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 40px;
  cursor: pointer;
  justify-content: center;
}

.summary-bottom {
  height: 250px;
  width: 100%;
  background-color: #c8d5f4;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 15px;
  flex: 1;
  align-items: center;
}

.summary-bottom:hover {
  background-color: #0a5fff;
  color: #ffffff;
}

.summary-img {
  height: 100px;
  width: 100px;
}

.summary-img-desc {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.summary-bold {
  font-weight: 600;
}

.summary-paragraph {
  text-align: center;
  font-size: 13px;
}

.img-box {
  height: 130px;
  width: 100%;
  padding: 10px;
}

.img-box:hover {
  background-color: #c8d5f496;
  cursor: pointer;
}

.img-box-active {
  background-color: #c8d5f4;
}

.summary-box-btn {
  background-color: #0a5fff;
  width: 200px;
}

.summary-main-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.summary-customize {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  height: 250px;
  width: 100%;
  overflow-y: auto;
}

.summary-customize-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.sumamry-color {
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: -230px;
}

.color-close-btn {
  position: absolute;
  top: 0;
  left: -255px;
  z-index: 999999999;
  cursor: pointer;
}

.chart-root-middle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.charts-main-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  margin: 0 auto;
  padding-bottom: 50px;
  overflow-y: auto;
}

.chart-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  height: 500px;
  position: relative;
  margin: 20px 0;
}

.chart-spacing {
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;
  height: 100%;
}

.pie-chart-legend-container {
  max-width: 200px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-wrap: wrap;
  height: 80px;
  left: 0;
  top: 60px;
}

.pie-chart-legend-container p {
  margin: 3px 0;
  font-size: 13px;
}

.legend-color {
  display: inline-block;
  padding: 3px;
  height: 3px;
  margin-right: 3px;
  border-radius: 50%;
}

.chart-root-b0ttom {
  text-align: center;
}

.chart-root-top-text {
  text-align: center;
  height: 40px;
  font-weight: 600;
  margin-bottom: 10px;
}

.summary-top {
  width: 100%;
  font-size: 14px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.top-left-text {
  font-weight: 500;
  font-size: 16px;
}

@media screen and (max-width: 390px) {
  .summary-content {
    width: 100%;
    gap: 20px;
    padding: 10px 15px;
  }

  .summary-top {
    width: 100%;
    font-size: 13px;
    padding: 8px;
    flex-direction: column;
  }

  .summary-bottom-container {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    overflow-y: auto;
    height: "100%";
  }

  .summary-bottom {
    height: 250px;
    width: 100%;
    flex-direction: row;
    padding: 10px;
    gap: 20px;
  }

  .summary-img {
    height: 70px;
    width: 70px;
  }

  .summary-img-desc {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
  }

  .summary-bold {
    font-weight: 600;
    width: 100%;
  }

  .summary-paragraph {
    text-align: left;
    width: 100%;
    font-size: 13px;
  }

  .chart-root-container,
  .table-root-container {
    background-color: #ffffff;
    height: calc(100vh - 170px);
    position: relative;
    padding: 15px;
    padding-bottom: 0;
    overflow-y: scroll;
    display: flex;
    color: #02105f;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 65px;
  }

  .chart-root-top span,
  .table-root-top span {
    display: none;
  }

  .table-root-top {
    padding: 8px 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .chart-root-top {
    width: 100vw;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
  }

  .table-top-1,
  .table-top-2,
  .chart-top-1,
  .chart-top-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .chart-top-2,
  .table-top-2 {
    width: 100%;
    align-items: flex-end;
  }

  .chart-top-2 button,
  .table-top-2 button {
    background-color: #c8d5f4;
    border: none;
  }

  .chart-top-2 button svg,
  .table-top-2 button svg {
    margin: 0;
    color: #02105f;
  }

  #hide-table-btn {
    display: none;
  }

  #bold svg {
    display: none;
  }

  .table-root-top p,
  .chart-root-top p {
    justify-content: flex-start;
    font-size: 12px;
  }

  .table-chart {
    margin-right: -30px;
  }

  .summary-main-box {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10% !important;
    padding: 11px !important;
  }

  .summary-main-box h2 {
    font-size: 18px;
  }

  .summary-customize {
    width: 100%;
    margin-left: 15px;
  }

  .sumamry-color {
    top: 100px;
    left: 30px;
  }

  .table-root-container .recharts-responsive-container {
    height: 300px !important;
    width: 100% !important;
    margin: 0;
  }

  .recharts-wrapper {
    height: 300px !important;
    width: 100% !important;
  }

  .recharts-wrapper svg {
    width: 100%;
    margin-left: -16px;
  }

  .color-close-btn {
    position: absolute;
    top: 100px;
    left: 85%;
    z-index: 999999999;
    cursor: pointer;
  }

  .chart-root-middle {
    flex-direction: column;
  }

  .charts-main-container {
    flex-direction: column;
    height: 100% !important;
    overflow-y: auto;
    /* width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    gap: 30px; */
    justify-content: flex-start;
  }

  .recharts-responsive-container {
    height: 300px !important;
  }
}

.responsive-chart {
}

@media print {
  .graph-container-box, .barchart-container, .charts-main-container{
    break-inside: avoid;
    margin: 50px 0 100px;
  }
}