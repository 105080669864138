.faq-help-root-container {
  /* background-color: #0a5fff; */
  height: 100%;
  display: flex;
  width: 100vw;
  flex-direction: column;
  gap: 40;
}
.faq-inner-container {
  width: 100vw;
  height: calc(100vh - 150px);
  margin: 0;
  color: #ffffff;
  gap: 0 !important;
}

.none{
  display: none;
}

.block{
  display: block
}

.pointer{
  cursor: pointer;
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-col{
  display: flex;
  flex-direction: column;
}

.flex-col-20{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex-col-30{
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.flex-col-10{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-row-20{
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.flex-row-30{
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.flex-row-10{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.flex-between{
  align-items: center;
  justify-content: space-between;
}

.flex-center{
  align-items: center;
  justify-content: center;
}

.flex-start{
  align-items: center;
  justify-content: flex-start;
}

.flex-1{
  flex: 1
}

.flex-3{
  flex: 4
}

.faq-top{
  background-color: #0a5fff;
  padding: 30px 80px;
  height: 200px;
  font-weight: 500;
  font-size: 20px;
}

.faq-title{
  text-decoration: underline;
  font-size: 50px;
  font-weight: 500;
}

.faq-bottom{
  color: #02105F;
  padding: 30px 80px;
  height: 100%;
  overflow-y: auto;
}

.active-text{
  color: #FFC60C;
  font-weight: 500;
}

.faq-bottom-right{
  padding-right: 15px;
}

.bottom-right-content{
  border-bottom: 1px solid #bfbfbfa1;

}
.faq-questions{
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-questions span{
  font-size: 22px;
  font-weight:  500;
}

.faq-answers{
  padding: 5px 20px;
}

@media screen and (max-width: 390px) {
  .faq-help-root-container {
    height: calc(100vh - 90px);
  }

  .faq-inner-container {
    width: 100vw;
    height: 100%;
    margin: 0;
    margin-bottom: 20px;
    color: #ffffff;
  }

  .faq-top{
    display: none !important;
  }

  .faq-bottom{
    display: flex;
    flex-direction: column !important;
    height: 100%;
    overflow-y: auto;
  }

  .faq-bottom-content{
    background-color: #C8D5F4;
    width: 390px;
    /* height: 100%; */
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 5px 8px;
    font-size: 10px;
  }

  .faq-bottom-left p{
    text-align: center;
    padding: 8px;
    width: 120px;
    background-color: #FFFFFF;
    color: #02105F;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .faq-scrollable{
    overflow-x: auto;
    width: 100%;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
  }

  .active-text{
    color: #ffffff !important;
    background-color: #0A5FFF !important;
    font-weight: 500;
  }

  .faq-bottom-right{
    font-size: 12px;
    padding: 0 15px;
  }

  .faq-answers{
    padding: 10px 30px;
  }
}