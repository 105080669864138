.period-root-container {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  height: calc(100vh - 210px);
}
.period-inner-root-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
  margin: 10px;
  padding: 20px 40px;
  gap: 80px;
}

.cancel-selection-btn {
  position: absolute !important;
  top: -10px;
  right: -10px;
  color: red !important;
}

.periods-selections-wrapper {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 30%;
  height: calc(100vh - 250px);
  margin-left: 5%;
}
.selection-container {
  background-color: #f4f5f9;
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.select-label {
  color: #011b60;
  font-size: 16px;
  font-weight: 500;
}
.select-dropdown {
  width: 100%;
  border: none;
}
/* style ppty defined by Select component */
.css-13cymwt-control {
  border: none !important;
  border-radius: 0px !important;
}

.css-t3ipsp-control {
  border: none;
  border-radius: 0px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.category-container {
  flex: 1;
  border-radius: 0;
  background-color: #fff;
}
.empty-category-container {
  flex: 1;
  border-radius: 0;
}
.compare-var-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 80px;
}
.compare-var-icon {
  height: 15px !important;
  width: 15px !important;
  color: #011b60;
}
.compare-var-text {
  color: #011b60;
  text-decoration-line: underline;
  font-size: 14px;
}
.header-text-container {
  width: 90%;
  margin: 20px auto;
}
.header-text {
  color: #011b60;
  font-size: 18px;
  font-size: 500;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.period-left-container {
  flex: 1;
  background-color: #f4f5f9;
  height: 100%;
}

.period-left,
.period-right {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #011b60;
}

.period-left {
  padding: 20px;
}

.period-left div {
  cursor: pointer;
  background-color: #ffffff;
  font-weight: 500;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.period-right-container {
  flex: 3;
  gap: 20px;
  height: 100%;
}

.period-right-top {
  display: flex;
  flex-direction: row;
  background-color: hsl(228, 29%, 97%);
  font-weight: 500;
  padding: 13px;
  flex: 10%;
  align-items: center;
  justify-content: space-between;
}

.calender-label{
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  position: relative;
}

.calendar-input{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.calendar-input input{
  border: none;
  background-color: #ffffff;
  font-size: 14px;
}

.calendar-container0.show1,
  .calendar-container1.show2{
  position: absolute;
  top: 50px;
  z-index: 1000;
}

.calendar-container0.show2{
  display: none;
}

.calendar-container1.show1{
  display: none;
}

.calender-label label{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.calendar-dropdown{
  width: 150px;
  font-size: 14px;
}

.calendar-dropdown .indicatorContainer{
  background-color: #E3E9FD;
  color: #011B60 !important;
}

.period-right-top span {
  font-size: 15px;
}

.period-right-bottom {
  flex: 90%;
  background-color: #f4f5f9;
  width: 100%;
  padding: 14px;
}
.period-dropdown-container {
  display: none;
}

.period-dropdown {
  position: relative;
  background-color: #ffffff;
  width: 40%;
  text-align: center;
  padding: 6px;
  padding-right: 12px;
  font-weight: 500;
  color: #011b60;
  font-size: 13px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-container {
  /* border: 3px solid red; */
  background-color: #e1e7f0;
  position: absolute;
  z-index: 999;
  top: 38px;
  left: 0;
  width: 100%;
  height: 220px;
}

.dropdown-category {
  padding: 8px;
  text-align: left;
  cursor: pointer;
  margin: 8px 0;
  font-weight: normal;
}

.period-dropdown {
  position: relative;
  background-color: #ffffff;
  width: 40%;
  text-align: center;
  padding: 6px;
  padding-right: 12px;
  font-weight: 500;
  color: #011b60;
  font-size: 13px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-container {
  /* border: 3px solid red; */
  background-color: #e1e7f0;
  position: absolute;
  z-index: 999;
  top: 38px;
  left: 0;
  width: 100%;
  height: 220px;
}

.dropdown-category {
  padding: 8px;
  text-align: left;
  cursor: pointer;
  margin: 8px 0;
  font-weight: normal;
}

.right-bottom-content {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
}

.period-left .period-category {
  background-color: #c8d5f4;
}

.data-bottom {
  /* border: 3px solid red; */
}

.right-bottom-content .d-inline-block {
  height: 100%;
}

.right-bottom-content .rdrMonthAndYearWrapper {
  height: 30px;
  padding-top: 0;
}

.right-bottom-content .rdrDateRangePickerWrapper,
.right-bottom-content .rdrCalendarWrapper {
  width: 100%;
  /* height: 100%; */
}

.right-bottom-content .rdrDateDisplayWrapper {
  background-color: #ffffff;
}

.right-bottom-content .rdrMonthAndYearPickers,
.right-bottom-content .rdrDateDisplay {
  display: none;
}

.right-bottom-content .rdrMonths {
  gap: 30px;
}

.right-bottom-content .rdrMonth {
  font-size: 13px;
  width: 100%;
  flex: 1;
}

@media screen and (max-width: 390px) {
  .period-inner-root-container {
    margin: 0;
    padding: 0;
    gap: 0;
  }

  .period-left {
    padding: 10px;
  }

  .period-left div {
    padding: 10px 25px;
    font-size: 14px;
    font-weight: 500;
    justify-content: flex-start;
  }

  .period-left-container {
    display: none;
  }

  .period-right-container {
    width: 100%;
    /* display: none; */
  }

  .period-right {
    height: 100%;
    overflow-y: auto;
    gap: 0;
  }

  .period-dropdown-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  margin-right: 40px;
}

  .right-bottom-content .rdrMonths {
    flex-direction: column;
    gap: 30px;
    background-color: #f5f5f5;
  }

  .period-right-top {
    background-color: #ffffff;
    font-size: 14px;
    padding: 13px;
    align-items: center;
    justify-content: center;
  }

  .right-bottom-content .rdrMonth {
    background-color: #ffffff;
  }
}

.rdr-buttons-position {
  bottom: 1rem;
}
