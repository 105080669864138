.login-root-container {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #0a5fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.logo-height {
  height: 70px;
  width: 70px;
}

#logo{
  height: 100px;
  width: 100px;
}

.Login_Wrapper {
  background-image: url("../../assets/images/LIP/Image\ 6.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 140%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Login_Card_Background {
  background-color: #0a60ff66;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 50px 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Login_Card {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 460px;
  height: auto;
  border-radius: 10px;
  gap: 2rem;
  padding: 10px 50px 50px;
}

.Login_Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.Login_Header_Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #284285;
  height: 100px;
  width: 160px;
}

.header-logo-height {
  height: 42px;
  width: 100%;
}

.Login_Header_FormTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  font-size: 15px;
  font-weight: 600;
}

.Login_Header_FormTitle a {
  color: #284285;
  text-decoration: none;
  letter-spacing: 0.6px;
}

#login_header_Home {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  text-decoration: underline;
}

.header-icon {
  margin-right: 8px;
  color: #0a5fff;
}

.Login_Form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.Login_TextWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.3rem;
}

.Login_LoginBtn {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 2px;
  background-color: #0a5fff;
  color: #ffffff;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  height: 40px;
}

.Login_Form_Label {
  font-size: 13px;
  color: #284285;
  font-weight: 600;
  margin-left: 15px;
}

.Login_Form_Input {
  background-color: #ffffff !important;
  width: 100%;
  border: 0.5px solid rgba(110, 125, 163, 0.49);
  height: 40px;
  padding-left: 15px;
  border: 0.5px solid #284285;
}

.Login_Form_ForgottenPassword {
  display: flex;
  align-items: center;
  justify-content: right;
  color: #284285;
  font-size: 14px;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

.Login_Form_ForgottenPassword a {
  text-decoration: none;
}

.Login_Bottom {
  margin-top: 20px;
  background-color: #ffffff;
  color: #284285;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  width: 460px;
  height: auto;
  justify-content: space-between;
  padding: 5px 50px;
}

.Login_Bottom_Powered_Container,
.Login_Bottom_Partner_Container {
  padding: 10px 0;
}

.Login_Bottom_Powered,
.Login_Bottom_Partner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.Login_Bottom_Logo {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Bottom-span {
  color: #ffffff;
  font-size: 12px;
  margin-top: 8px;
}

.login-content{
  position: relative;
  width: 100%;
}

.showLogin{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 390px) {
  .Login_Card {
    padding: 10px 25px 40px;
    width: 100%;
  }

  .Login_Bottom {
    width: 100%;
  }

  .Bottom-span {
    display: none;
  }

  .header-logo-height {
    height: 54px;
    width: 80%;
  }
  .Login_Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
  }

  .Login_Header span{
    flex: 1;
  }

  #login_header_Home {
    width: 100%;
    font-size: 10px;
  }

  .Login_Header_Logo {
    height: 57px;
  }

  #login_header_Home svg{
    display: none;
  }

  #login_header_Home {
    justify-content: flex-end;
  }

  #login_header_Home span{
    text-align: end;
  }
  
}