.base-component-root {
  background-color: #0a5fff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
  color: #fff;
  gap: 30px;
  padding-top: 30px;
}
.select_program_text {
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  margin-bottom: 10px;
}
.back_to_login_text {
  width: 85%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;
}
.select_program_areas_container {
  display: flex;
  gap: 5%;
  overflow-y: auto;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  width: 85%;
}
.selceted-program-area {
  border: 1.5px solid #152d6e;
}
.individual-area-container {
  background-color: #fff;
  display: flex;
  padding: 20px;
  gap: 16px;
  margin-bottom: 40px;
  cursor: pointer;
  height: 100px;
  width: 30%;
  border-radius: 10px;
  align-items: center;
}

.individual_area_container:hover {
  background-color: #c8d5f4;
}

.active-vaccine-type {
  background-color: #c8d5f4;
}

.area_text {
  color: #011b60;
  font-size: 18px;
  font-weight: 500;
}

.program-area-right {
  width: 70%;
  display: flex;
  height: calc(100vh - 165px);
  flex-direction: column;
  overflow-x: hidden;
  box-sizing: content-box;
  flex: 1;
}
.program-area-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -5px;
}

.program-area-title {
  font-size: 24px;
  color: #011b60;
  font-weight: 500;
  margin-top: 30px;
  margin-left: 50px;
}
.compare-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  margin-right: 30px;
  margin-top: 10px;
}
.compare-icon {
  height: 15px !important;
  width: 15px !important;
  color: #011b60;
}

.reset-indicator-icon {
  height: 15px !important;
  width: 15px !important;
  background-color: #011b60;
  color: #fff;
  border-radius: 15px;
}
.compare-text {
  color: #011b60;
  text-decoration-line: underline;
  font-size: 14px;
}

.indicators_inner_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
  margin: 25px 0;
}

.indicators-container {
  display: flex;
  justify-content: space-evenly;
}

.indicator-item-container {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  gap: 10px;
  height: 50px !important;
  cursor: pointer;
}
.indicator-item-container input {
  width: 20px !important;
}
.indicator-text {
  font-size: 14px;
  color: #011b60;
  text-align: left;
}
.selceted-indicators-container {
  /* background-color: #f4f5f9; */
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  gap: 10px;
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
  margin: 60px 30px 25px 0;
  padding-right: 20px;
}

.selected-indicator-item {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  gap: 10px;
  cursor: pointer;
}

.empty-container {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 390px) {
  .base-component-root {
    gap: 10px;
    padding: 20px;
  }

  .select_program_text {
    width: 100%;
    font-size: 14px;
  }

  .select_program_areas_container {
    position: relative;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    overflow-y: hidden;
    flex-wrap: nowrap;
    margin-right: 0;
    margin-left: 0;
  }

  .individual-area-container {
    flex: 1;
    background-color: #fff;
    display: flex;
    padding: 20px;
    gap: 16px;
    margin-bottom: 0;
    cursor: pointer;
    width: 100%;
    border-radius: 10px;
    align-items: center;
  }

  .back_to_login_text {
    height: 75px;
    text-decoration: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c8d5f4;
  }

  .back_to_login_text span {
    background-color: #0a5fff;
    color: #ffffff;
    width: 100%;
    padding: 8px;
  }

  .program-area-right {
    width: 100%;
    display: flex;
    height: calc(100vh - 130px);
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: content-box;
    flex: 1;
  }

  .back_to_login_text {
    display: none;
  }
}
