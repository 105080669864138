.page-wrapper-root {
  background-color: #fff;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20;
}

.steps-container {
  background-color: #0a5fff;
  height: 70px;
  padding: 15px 100px;
}

.css-z7uhs0-MuiStepConnector-line {
  border: 1px dashed #fff !important;
}
.page-footer {
  height: 50px;
  width: 100%;
  background-color: #c8d5f4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.custom-page-footer {
  display: none;
  height: 70px;
  width: 100%;
  background-color: #c8d5f4;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.continue-btn {
  height: 35px;
  color: #fff;
  background-color: #0a5fff;
  margin-right: 50px;
  border: none;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  cursor: pointer;
}
.go-back-btn {
  height: 35px;
  color: #02105f;
  background-color: #fff;
  margin-right: 20px;
  border: none;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  cursor: pointer;
}

.go-dashboard-btn{
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none !important;
}

.go-dashboard-btn span{
  display: block;
  padding: 10px;
  width: 100%;
  background-color: #0a5fff;
  color: #ffffff;
}

.custom-page-footer button {
  cursor: pointer;
  border: none;
  border-radius: 3px;
  border: 1px solid #02105f6e;
  background-color: #c8d5f4;
  color: #011b60;
  font-weight: bold;
  padding: 8px;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 390px) {

  .base-component-root {
    height: calc(100vh - 170px);
  }

  .contents-container{
    height: 100%;
  }

  .page-footer {
    display: none;
  }

  .custom-page-footer {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .continue-btn {
    margin-right: 10px;
  }

  .program-stepper{
    font-size: 12px;
    width: 100%;
  }

  /* .page-wrapper-root .css-m5vj9m-MuiStepper-root{
    display: none;
  } */

  .steps-container {
    /* display: none; */
    width: 390px;
    height: 35px;
    overflow-x: scroll;
    padding: 20px 10px;
  }

  .steps-container button{
    padding: 7px;
    width: 115px;
  }

  .steps-container .MuiStepConnector-root{
    display: none;
  } 

  .steps-container svg{
    font-size: 12px;
  }

  /* .steps-container span{
    font-size: 10px;
  } */
}