.layout-root {
  display: flex;
  /* background-color: #f4f5f9; */
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}
.layout-main-section {
  position: relative;
  height: calc(100vh - 100px);
}

.layout-footer-section {
  position: relative;
  height: 30px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f5f9;
  z-index: 2;
}
.layout-footer-text {
  font-size: 13px;
  color: #011b60;
  margin: 0 50px;
}

.buttom-links {
  text-decoration: none;
  color: #011b60;
  font-weight: 600;
}

@media screen and (max-width: 390px) {
  .layout-main-section {
    position: relative;
    height: calc(100vh - 130px);
  }
}
