.about-root-container {
  background-color: #fff;
  height: 100%;
  display: flex;
  width: 100vw;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden;
}
.about-first-container {
  background-color: #0a5fff;
  width: 100%;
  padding: 20px 50px 50px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.about-first-left {
  width: 50%;
}
.about-first-right {
  width: 50%;
  position: relative;
  /* margin-right: -50px; */
}
.about-acm-img {
  position: absolute;
  right: -45px;
  bottom: -180px;
  /* width: 90%; */
  width: 550px;
}
.title {
  font-size: 32px !important;

  font-weight: 500 !important;
}
.about-paragraphs {
  font-size: 18px !important;
  line-height: 1.7;
  margin-top: 20px;
}

.about-second-container {
  background-color: #fff;
  width: 100%;
  padding: 90px 50px 20px;
  color: #02105f !important;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: space-between;
}
.about-second-right {
  width: 50%;
}

.about-second-left {
  width: 50%;
  position: relative;
}
/* .why-acm-img {
  width: 100%;
  position: absolute;
  top: -230px;
  left: -130px;
} */

.why-acm-img {
  width: 100%;
}

.about-third-container {
  background-color: #c8d5f4 !important;
  width: 100%;
  padding: 20px 50px;
  color: #02105f !important;
}

.third-inner-container {
  background-color: #0a5fff;
  color: #fff;
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;
  margin: 50px 0px;
  border-radius: 10px;
  gap: 30px;
}

.bottom-img-container {
  width: 50%;
}

.bottom-img {
  width: 100%;
  display: block;
}
