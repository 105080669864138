.dashboard-root {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-image: url("../../assets/images/Image\ 6.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.dashboard-inner {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px;
  z-index: 2;
}
.dashboard-main {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.dashboard-bg-opacity {
  background-color: #0a5fff;
  opacity: 0.95;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
}
.dashboard-cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.dashboard-header{
  font-size: 40px;
  font-weight: 400;
  color: #ffffff
}

.dashboard-card {
  background-color: #fff;
  display: flex;
  gap: 20px;
  border-radius: 10px;
  height: 200px;
  align-items: center;
  padding: 25px 35px;
  width: 550px;
  color: #02105f;
  cursor: pointer;
}

.dashboard-card:hover {
  background-color: #c8d5f4;
}

.dashboard-card-img-container {
  height: 100px;
  width: 100px;
}

.dashboard-card-img-icon{
  height: 100px;
  width: 100px;
}

.dashboard-card-right {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 80%;
}

.dashboard-title {
  font-size: 20px;
  font-weight: 500;
}

.dashboard-description {
  font-size: 17px;
  font-weight: 400;
}
.right-side {
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.right-title-text {
  font-size: 40px;
  font-weight: 500;
}

.right-description-text {
  font-size: 22px;
}


@media screen and (max-width: 390px) {
  .dashboard-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    z-index: 2;
  }

  .dashboard-header{
    font-size: 20px;
  }

  .dashboard-main {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }

  .right-title-text {
    display: none;
  }

  .right-description-text {
    font-size: 15px;
  }

  .dashboard-card {
    display: flex;
    flex-direction: column;
    gap: 10x;
    height: 200px;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    width: 100%;
  }

  .dashboard-card-img-container {
    height: 45px;
    width: 100%;
  }

  .dashboard-card-img-icon {
    height: 100%;
    width: 45px;
  }

  .dashboard-card-right {
    gap: 10px;
    width: 100%;
  }

  .dashboard-title {
    font-size: 16px;
  }
}