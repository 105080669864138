.contact-root-container {
  background-color: #0a5fff;
  height: 100%;
  display: flex;
  width: 100vw;
  flex-direction: column;
  gap: 40;
}
.contact-inner-container {
  /* width: 93vw; */
  height: calc(100vh - 170px);
  padding: 20px 30px;
  background-color: #ffffff;

  color: #02105F;
}

label {
  color: #02105F;
  font-weight: 500;
}

.contact-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 90px;
  font-size: 35px;
  font-weight: 600;
  height: 100%;
  color: #ffffff;
}

input {
  border: 1px solid #02105F;
  background-color: transparent;
  padding: 10px;
  width: 100%;
}

.none {
  display: none;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex-col-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-row-20 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.flex-row-30 {
  display: flex;
  flex-direction: row;
  gap: 90px;
}

.flex-row-10 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.flex-row-20 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.flex-row-3 {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.flex-between {
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-start {
  align-items: center;
  justify-content: flex-start;
}

.flex-1 {
  flex: 1;
}

.flex-3 {
  flex: 4;
}

.contact-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.contact-form {
  width: 100%;
  height: 100%;
  padding: 40px 0 20px 50px;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact-form form{
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.group-form {
  width: 100%;
}

.group-form label {
  flex: 1;
  font-size: 12px;
}

.group-form input {
  margin-top: 5px;
}

.contact-textarea {
  width: 100%;
  margin-top: 20px blue;
}

.contact-textarea label {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.contact-textarea textarea {
  margin-top: 5px;
  border: 1px solid #02105F;
  background-color: transparent;
}

.contact-btn {
  width: 100%;
  /* margin-top: 30px; */
}

.contact-btn button {
  background-color: #0A5FFF;
  width: 40%;
  border-radius: 2px;
  padding: 10px;
  color: #FFFFFF;
  letter-spacing: .7px;
  font-weight: 600;
}

.contact-text {
  margin-top: 20px;
  font-size: 15px;
}

.contact-image {
  width: 100%;
  height: 90%;
  margin-top: -50px;
  margin-right: -7px;
}

@media screen and (max-width: 390px) {
  .contact-root-container {
    height: calc(100vh - 90px);
    width: 100%;
  }
  .contact-inner-container {
    margin: 0;
    padding: 30px 20px;
    height: 100%;
    width: 100%;
  }
  .contact-header {
    font-weight: 500;
  }

  .contact-header1 {
    display: block !important;
    font-weight: 500;
  }

  .contact-image {
    display: none;
  }

  .contact-form {
    width: 100%;
    height: 100%;
    padding: 0;
    padding-top: 15px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .group-form {
    width: 100%;
    display: flex;
    flex-direction: column !important;
  }
}
