.barchart-root-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 125px);
  position: relative;
}

.barchart-container {
  width: 100%;
  background-color: #f4f5f9;
  /* height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  gap: 40px; */
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 16px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.barchart-container-box {
  width: 95%;

  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 35px;
  flex-direction: column;
  color: #152d6e;
  font-size: 13px;
}

.graph-main-inner-container {
  height: 100%;
  width: 70%;
  overflow-x: auto;
}

.graph-info-container {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20;
  padding: 25px 5px;
}

.graph-info-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}
.indicator-one-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #152d6e;
}
.indicator-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
}
.indicator-two-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #ffc60c;
}
.indicator-three-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #ffc4c4;
}

.indicator-text {
  font-size: 12px;
  width: 90%;
  margin-left: 10px;
}

.recharts-text {
  font-size: 13px;
}

@media print {
  .graph-container-box{
    break-inside: avoid;
    margin: 50px 0 100px;
  }
}