.summary-img {
  height: 100px;
  width: 100px;
}

.summary-img-desc {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.summary-box-btn {
  background-color: #0a5fff;
}

.img-box {
  height: 130px;
  width: 100%;
  padding: 10px;
}

.img-box:hover {
  background-color: #c8d5f496;
}

.img-box-active {
  background-color: #c8d5f4;
}

.modal-container{
  position: absolute;
  display: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 450px;
  color: #011B60;
  border-radius: 8px;
  text-align: center;
  padding: 32px;
  background-color: #ffffff;
}

.modal-content{
  display: flex;
  flex-direction: row;
  gap: 35px;
  margin-top: 32px;
  margin-bottom: 24px;
}

@media screen and (max-width: 390px) {
  .summary-img {
    height: 70px;
    width: 70px;
  }

  .summary-img-desc {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
  }

  .modal-container{
    position: absolute;
    /* display: flex; */
    top: 25%;
    left: 5%;
    transform: none;
    width: 350px;
    height: 450px;
    color: #011B60;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    background-color: #ffffff;
  }

  .modal-content{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
