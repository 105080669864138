.error-page-root-container {
  background-color: #0a5fff;
  height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #fff;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.error-title {
  font-size: 40px;
  font-weight: 600;
}

.error-sub-title {
  font-size: 28px;
  margin-bottom: 30px;
}

.back-home-button {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
}
