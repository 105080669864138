.data-select-root {
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.data-select-container {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px 20px;
  overflow-y: auto;
}

.data-selected-program-area {
  background-color: #011b60;
  color: #fff;
  font-size: 20px;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.program-areas-left-side {
  width: 20%;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  margin-left: 5%;
}

.selected-data-source {
  background-color: #c8d5f4 !important;
}

.data-select {
  background-color: #fff;
  display: flex;
  padding: 15px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.data-select:hover {
  background-color: #c8d5f4;
}

.inactive-area {
  opacity: 0.5;
}

.data-selected-text {
  color: #011b60;
  font-size: 18px;
}

.program-area-right {
  width: 70%;
  display: flex;
  height: calc(100vh - 165px);
  flex-direction: column;
  overflow-x: hidden;
  box-sizing: content-box;
  flex: 1;
}

.program-area-right-text {
  height: 20px;
  color: #011b60;
  margin: 30px;
  font-size: 20px;
}

.program-area-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -5px;
}

.program-area-title {
  font-size: 24px;
  color: #011b60;
  font-weight: 500;
  margin-top: 30px;
  margin-left: 50px;
}

.compare-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  margin-right: 30px;
  margin-top: 10px;
}

.compare-icon {
  height: 15px !important;
  width: 15px !important;
  color: #011b60;
}

.reset-indicator-icon {
  height: 15px !important;
  width: 15px !important;
  background-color: #011b60;
  color: #fff;
  border-radius: 15px;
}

.compare-text {
  color: #011b60;
  text-decoration-line: underline;
  font-size: 14px;
}

.indicators_inner_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 25px 0;
  padding-top: 30px;
}

.program-area-img-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a5fff;
  width: 250px;
  height: 250px;
  border-radius: 10px;
}

.program-area-img-section .program-area-icon {
  width: 150px !important;
  height: 150px !important;
}

.indicators-container {
  display: flex;
  justify-content: space-evenly;
}

.indicator-item-container {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  gap: 10px;
  height: 50px !important;
  cursor: pointer;
}

.select-all-indicator-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px 20px;
  gap: 10px;
  height: 50px !important;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
}

.select-all-indicator-item-container input {
  width: 20px;
}

.indicator-text {
  font-size: 14px;
  color: #011b60;
  text-align: left;
}

.indicator-left {
  width: 60%;
  height: 100%;
  margin-top: 120px;
}

.indicator-right {
  width: 60%;
  height: calc(100vh - 250px);
  position: relative;
}

.selceted-indicators-container {
  height: 100%;
  /* background-color: #f4f5f9; */
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  gap: 10px;
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
  margin: 60px 30px 25px 0;
  padding-right: 20px;
}

.selected-indicator-item {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  gap: 10px;
  cursor: pointer;
}

.empty-container {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 60%;
}

.empty-container-left {
  width: 400px;
  height: 400px;
}

.empty-container-left p {
  color: #011b60;
}

.empty-container-right {
  display: flex;
  width: 30%;
  height: calc(100vh - 250px);
  position: relative;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 390px) {
  .data-select-root {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 20px;
  }

  .data-select-container {
    overflow-y: auto;
  }

  .program-areas-left-side {
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    overflow-y: hidden;
  }

  .program-area-right {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-x: hidden;
    box-sizing: content-box;
    flex: 1;
  }

  #scrollable {
    overflow: auto;
  }

  .program-area-right-text {
    height: 20px;
    margin: 15px;
    font-size: 14px;
  }

  .empty-container-right {
    display: none;
  }

  .indicator-right {
    width: 100%;
  }
}
