.ManagePasswordModal_wrapper {
  display: flex !important;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  gap: 2rem;
  background-color: #fff;
  width: 350px;
  padding: 2rem;
  border-radius: 15px;
  border: none;
  max-height: 80vh;
  overflow: auto;
}
.ManagePasswordModal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ManagePasswordModal_header > span {
  color: #011b60;
  font-weight: 800;
  font-size: 20px;
}

.ManagePasswordModal_formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
}

.ManagePasswordModal_textWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2rem;
}

.ManagePasswordModal_inputWrapper {
  display: flex;
  flex-direction: column;
  color: #011b60;
  gap: 0.5rem;
}
.ManagePasswordModal_inputWrapper > label {
  font-size: 10px;
  font-weight: 600;
}
.ManagePasswordModal_password {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.ManagePasswordModal_password > input {
  color: #011b60;
  padding: 0.5rem 0.5rem;
  border-radius: 0%;
  border: 0.5px solid rgba(21, 45, 110, 0.4);
  width: 100%;
}

.ManagePasswordModal_buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.ManagePasswordModal_leftBtn {
  padding: .7rem 1.5rem;
  border: 1px solid #011b60;
  background-color: #fff;
  color: #011b60;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 600;
}
.ManagePasswordModal_rightBtn {
  padding: .7rem 2rem;
  background-color: #152d6e;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.ManagePasswordModal_password {
  position: relative;
}

.ManagePasswordModal_showPassword {
  position: absolute;
  right: 1rem;
  bottom: 0;
  cursor: pointer;
}
