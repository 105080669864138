.topBarWrapper {
  background-color: #fff;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.topbarAvatar {
  position: "relative";
  background: "red";
  cursor: pointer;
}
.topbarMenu {
  position: absolute;
  z-index: 200;
  padding: 1rem;
  top: 4rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background-color: #f4f5f9;
  border-radius: 5px;
  
}

.top-bar-drawer .MuiPaper-root{
  width: 230px;
}

.top-bar-img{
  width: 250px;
  height: 50px;
}

.top-bar-menu{
  display: none !important;
  width: 20px;
  height: 30px;
  margin-top: 90px;
}

.top-bar-left-side {
  display: flex;
  margin-left: 50px;
  gap: 6px;
  color: #02105f;
  cursor: pointer;
  margin-top: -5px;
}

.top-bar-right{
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.acsm-text {
  font-weight: 600;
  color: #0a5fff;
  font-size: 40px;
}
.data-hub-text {
  font-size: 14px;
  margin-top: -10px;
  font-weight: 400;
  letter-spacing: 3.5px;
}
.top-menu-list-wrapper {
  display: flex;
  flex-direction: row;
  gap: 50px;
  list-style-type: none;
  margin-right: 50px;
}

.top-login-btn {
  height: 35px;
  color: #fff;
  background-color: #0a5fff;
  margin-right: 50px;
  border: none;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 390px) {
  .topBarWrapper {
    height: 60px;
  }

  .top-bar-left-side {
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    padding: 10px;
    height: 70px;
    width: 230px;
    margin-top: 0;
    margin-left: 10px;
  }

  .top-bar-img{
    width: 140px;
    height: 80px;
  }
  
  .top-bar-menu{
    display: inline-block !important;
    width: 20px;
    height: 30px;
    margin-top: 0;
  }

  .top-bar-right{
    display: none; 
  }
}