.tableview-root-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 125px);
  position: relative;
}

.tableview-left {
  width: 100%;
  background-color: #f4f5f9;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 40px;
}
.graph-container-box {
  width: 95%;
  height: 90vh;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 35px;
  margin: 10px 0 50px;
}

.graph-main-inner-container {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.graph-info-container {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20;
  padding: 25px 5px;
}

.graph-info-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}
.indicator-one-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #152d6e;
}
.indicator-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
}
.indicator-two-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #ffc60c;
}
.indicator-three-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #ffc4c4;
}

.indicator-text {
  font-size: 18px;
  width: 90%;
  margin-left: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  color: #02105f;
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

/* th:first-child,
td:first-child {
  width: 70%;
}

th:last-child,
td:last-child {
  width: 30%;
} */

@media print {
  .graph-container-box,.barchart-container, .charts-main-container{
    break-inside: avoid;
    margin: 50px 0 100px;
  }
}
