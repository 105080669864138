.location-root-container {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
}
.inner-root-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 180px);
  flex: 1;
  position: relative;
}

.cancel-selection-btn {
  position: absolute !important;
  top: -10px;
  right: -10px;
  color: red !important;
}

.level-selections-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 90%;
  height: calc(100vh - 250px);
  margin: auto;
}
.level-selection-container {
  width: 30%;
  background-color: #f4f5f9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.level-selection-map {
  background-color: #0a5fff;
  height: 100%;
  width: 65%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 30px;
  gap: 30px;
  color: #fff;
}

.level-map {
  height: 110px;
  width: 150px;
}

.level-map-header {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
}

.level-map-body {
  font-size: 13px;
}

.select-label-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.select-label {
  color: #011b60;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.select-dropdown {
  width: 100%;
  border: none;
}
/* style ppty defined by Select component */
.css-13cymwt-control {
  border: none !important;
  border-radius: 0px !important;
}

.css-t3ipsp-control {
  border: none;
  border-radius: 0px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.level-top-container {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 150px;
}

.level-bottom-container {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 150px;
}
.empty-category-container {
  flex: 1;
  border-radius: 0;
}
.compare-var-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 80px;
}
.compare-var-icon {
  height: 15px !important;
  width: 15px !important;
  color: #011b60;
}
.compare-var-text {
  color: #011b60;
  text-decoration-line: underline;
  font-size: 14px;
}
.header-text-container {
  width: 90%;
  margin: 20px auto;
}
.header-text {
  color: #011b60;
  font-size: 18px;
  font-size: 500;
}

.facilities-section-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.empty-facility-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  color: #011b60;
}
.facilities-head-text {
  color: #011b60;
  font-size: 14px;
  font-weight: 500;
  margin: 20px;
}
.facilities-container {
  background-color: #f4f5f9;
  padding: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 0;
  height: calc(100vh - 500px);
}
.facility-container {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  gap: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.facility-text {
  font-size: 14px;
  color: #011b60;
  text-align: left;
}

@media screen and (max-width: 390px) {
  .level-selections-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    height: calc(100vh - 160px);
    margin: 15px auto;
  }

  .level-top-container {
    gap: 10px;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    min-height: 370px;
  }

  .level-selection-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 20px; */
    position: relative;
  }

  .level-bottom-container{
    height: 100%;
    flex-direction: column;
  }

  .level-selection-map {
    background-color: #0a5fff;
    height: 150px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 15px;
    color: #fff;
  }

  .level-map-header {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .level-selection-map svg {
    flex: 1;
  }
  .level-selection-map div {
    flex: 2;
  }

  .select-label-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}